import { FormGroup, Form, Input, Box, Button, Card, CardHeader, CardContent, Table, TableContainer, TableBody, TableCell, TableHead, TableRow, Chip, CircularProgress, Divider, FormControl, IconButton, InputLabel, MenuItem, Paper, Select, SelectChangeEvent, Skeleton, Tab, Tabs, TextField, Typography } from "@mui/material";
import { observer } from "mobx-react";
import React, { useEffect, useState, useRef } from "react"
import Layout from "../../components/shared-layout";
import Breadcrumb from "../../components/shared-breadcrumb";
import useStores from "../../hooks/use-stores"
import VmButton from "../../components/shared-button";
import VmModal from "../../components/shared-modal";
import VmTable from "../../components/shared-table";
import TabPanel from "../../components/shared-tab-panel";
import { GetCollegeId, GetOrgId, isBrowser, PAGE_SIZE, ProdEnvCheck } from "../../constants/options";
import ContentLayout from "../../components/shared-content-layout";
import { t } from "i18next";
import { TD_FIRST, TD_FIRST_PENDING_REQUEST, TD_LAST, TD_LAST_PENDING_REQUEST, TD_NORMAL, TD_NORMAL_PENDING_REQUES, TD_NORMAL_PENDING_REQUEST, THEME_COLOR, THEME_HIGHLIGHT } from "../../constants/style";
import FilePresentIcon from '@mui/icons-material/FilePresent';
import { constructImgUrl } from "../../constants/settings";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import HistoryIcon from '@mui/icons-material/History';
import HourglassFullIcon from '@mui/icons-material/HourglassFull';
import TopicIcon from '@mui/icons-material/Topic';
import { composeInitialProps } from "react-i18next";
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import PendingIcon from '@mui/icons-material/Pending';
import { minHeight } from "@mui/system";
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import SlideDown from "react-slidedown";
import FileDownloadDoneIcon from '@mui/icons-material/FileDownloadDone';
import FileDownloadOffIcon from '@mui/icons-material/FileDownloadOff';
import VmVisitSlideDownCard from "../../components/shared-VisitSlideDownCard";
import TraineeshipCard from "../../components/shared-traineeship-card";
import { date } from "yup";
import RefreshIcon from '@mui/icons-material/Refresh';
function TabProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}



const StudentOverviewPage = observer(() => {
  const { studentStore, rootStore } = useStores();
  const { visitStore } = useStores();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [currentStudent, setCurrentStudent] = useState<any>();
  const [currentVisit, setCurrentVisit] = useState<any>();
  const [page, setPage] = useState<number>(1);
  const [visitHistoryPage, setVisitHistoryPage] = useState<number>(1);
  const [visitCurrentPage, setVisitCurrentPage] = useState<number>(0);
  const [tabValue, setTabValue] = useState<number>(1);

  //trainneship
  const [traineeshipTabValue, setTraineeshipTabValue] = useState<number>(1);
  const [currentTraineeShip, setCurrentTraineeShip] = useState<any>();
  const [currentTraineeShipPage, setCurrentTraineeShipPage] = useState<any>(0);
  const [traineeFold, setTraineeFold] = useState<number>(0);

  // visit tab: fold / unfold
  const [fold, setFold] = useState<number>(0);

  // create visit request
  const [createVisitModal, setCreateVisitModal] = useState<boolean>(false); // modal display
  const [currentCreateVisit, setCurrentCreateVisit] = useState<any>(); // data store
  const [confirmButton, setConfirmButton] = useState<boolean>(false);
  // create visit request prop
  const [createVisitProp, setCreateVisitProp] = useState({
    visitId: 0,
    startTime: "",
    endTime: "",
    locationId: null
  })

  // check time availability
  const [checkTimeAvailability, setCheckTimeAvailability] = useState<any>({
    startTime: "",
    endTime: "",
    trainerId: 0,
    traineeId: 0,
    visitId: 0,
    traineeshipId: 0
  });

  const [checkTimeResponse, setCheckTimeResponse] = useState<any>([]);


  useEffect(() => {
    setCheckTimeAvailability({
      ...checkTimeAvailability,
      startTime: createVisitProp.startTime,
      endTime: createVisitProp.endTime
    });
    console.log(checkTimeAvailability);
  }, [createVisitProp]);

  const [visitFinalStartTime, setVisitFinalStartTime] = useState<Date>(new Date());

  //loading
  const [loadingVisitTable, setLoadingVisitTable] = useState<boolean>(false);
  const [showNeedRequestList, setShowNeedRequestList] = useState<boolean>(false);
  const visitRef = useRef(null);
  useEffect(() => {
    studentStore.getStudentProfileListByOrgId(GetOrgId(), PAGE_SIZE, 0);
    //getOrgList();
  }, []);

  const handleShowModal = (id: number, isHistory: boolean) => {
    let target: any;
    if (isHistory)
      target = studentStore.visitHistoryList.data.find(v => v.id == id);
    else
      target = studentStore.visitCurrentList.data.find(v => v.id == id);
    setCurrentVisit(target);
    setShowModal(true);
  }

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
    if (currentStudent) {
      if (newValue == 0 && studentStore.visitHistoryList.totalCount == 0) {
        // refresh visit list if history is never load
        //studentStore.getVisitListByStudentId(currentStudent.id, true, PAGE_SIZE, (visitHistoryPage - 1) * PAGE_SIZE);
      } else if (newValue == 1 && studentStore.visitCurrentList.totalCount == 0) {
        //studentStore.getVisitListByStudentId(currentStudent.id, false, PAGE_SIZE, (visitCurrentPage - 1) * PAGE_SIZE);
      }
    }
  };

  // june

  const onToggleFold = (id: number) => {
    if (fold === id) {
      setFold(0);
    } else {
      var delayInMilliseconds = 500; //1 second
      visitStore.setVisitTableLoading(true);
      setTimeout(function () {
        visitStore.getChangeRequestListByVisitId(id);
      }, delayInMilliseconds);
      // visitStore.getChangeRequestListByVisitId(id);

      setFold(id);
      // onToggleChangeVisitList(id)
    }
  };

  const onToggleTraineeshipFold = (id: number) => {
    if (traineeFold === id) {
      setTraineeFold(0);
    } else {
      setTraineeFold(id);
    }
  };

  const onToggleChangeVisitList = (id: number) => {
    visitStore.getChangeRequestListByVisitId(id);
  }

  const statusPostHandler = (visitId: number, changeId: number, status: boolean) => {
    const res = confirm(`Do you want to ${status === true ? "Accept the request?" : "Reject the request?"}?`);
    if (res) {
      visitStore.updateChangeRequestStatus(changeId, status).then(() => {
        //studentStore.getVisitListByTraineeshipId(currentTraineeShip)
        let thisVisit = visitStore.visitChangeList.find(v => v.id === changeId);
        console.log(`visit accept status , status: ${status}, orgAccept: ${thisVisit.orgAccept}, trainerAccept: ${thisVisit.trainerAccept}, traineeAccept: ${thisVisit.traineeAccept}`)
        if (status === true && thisVisit.trainerAccept === true && thisVisit.traineeAccept === true) {
          studentStore.getVisitListByTraineeshipId(currentTraineeShip.id);
          visitStore.getChangeRequestListByVisitId(visitId);
        } else {
          visitStore.getChangeRequestListByVisitId(visitId);
        }
      })
      rootStore.notify('New visit time requested!', 'success');
    }
  }

  const handleCloseCreateVisitModal = () => {
    setCreateVisitModal(false)
    setCreateVisitProp({
      visitId: 0,
      startTime: "",
      endTime: "",
      locationId: null
    })
    setCheckTimeResponse([]);
    setConfirmButton(false);
  }

  const handleCreateVisitModal = (id: number) => {
    let target = studentStore.traineeshipVisitList.data.find((v: any) => v.id == id);
    console.log("visitmodaltarget: ", target)
    setCurrentCreateVisit(target);
    //setCurrentTraineeShip(target.location.traineeships);
    setCreateVisitProp({ ...createVisitProp, "visitId": target.id, "startTime": target.startTime, "endTime": target.endTime, "locationId": target.locationId })
    setCreateVisitModal(true);
    getOrgList();
  }

  const handleCheckTimeAvailability = (st: string, et: string, trainerId: number, traineeId: number) => {
    setCheckTimeAvailability({ ...checkTimeAvailability, startTime: st, endTime: et, trainerId, traineeId });
    console.log(checkTimeAvailability);
  }

  const createVisitPostHanlder = () => {
    // e.preventDefault();
    const date = new Date(Date.now());
    console.log(`final start time day: ${visitFinalStartTime.getDay()} hour: ${visitFinalStartTime.getHours()}`)
    console.log(`now day: ${date.getDay()} hour: ${date.getHours()}`)
    const day: number = visitFinalStartTime.getDate() - date.getDate();
    const hour: number = ((day * 24) + visitFinalStartTime.getHours()) - date.getHours();
    const minute: number = ((hour * 60) + visitFinalStartTime.getMinutes() - date.getMinutes());
    console.log(minute);

    handleCloseCreateVisitModal();
    visitStore.checkTimeAvailability(checkTimeAvailability).then(() => {
      if (visitStore.visitOverlapList.length !== 0) {
        setCheckTimeResponse(visitStore.visitOverlapList)
      } else {
        if (minute > 1440) {
          setCreateVisitModal(false);
          setCurrentTraineeShip(null);
          visitStore.setVisitTableLoading(true)
          visitStore.createVisitChangeRequest(createVisitProp).then(() => {
            rootStore.notify('New visit time requested!', 'success');
            visitStore.getChangeRequestListByVisitId(createVisitProp.visitId)
          })
          //window.location.reload()
        } else {
          rootStore.notify('If you need to change your visit with less than 24 hours notice, contact Head Office directly on 1800 286 916.', 'error');
        }
      }
    })



    // if (minute > 1440) {
    //   setCreateVisitModal(false);
    //   visitStore.setVisitTableLoading(true)
    //   visitStore.createVisitChangeRequest(createVisitProp).then(() => {
    //     rootStore.notify('Successful', 'success');
    //     visitStore.getChangeRequestListByVisitId(createVisitProp.visitId)
    //   })
    //   //window.location.reload()
    // } else {
    //   rootStore.notify('If you need to change your visit with less than 24 hours notice, contact Head Office directly on 1800 286 916.', 'error');
    // }
  }

  const createVisitPropHandler = (e: any) => {
    const name = e.target.name;
    const value = e.target.value;
    setCreateVisitProp({ ...createVisitProp, [name]: value })
    console.log(createVisitProp)
  }

  const getOrgList = () => {
    visitStore.getOrganisationListbyGrouId(localStorage.GROUPID)

  }

  return (
    <ContentLayout
      pageName={t('Student List')}
      pageHeading={t('Student List')}
      breadCrumb={[
        // { label: t('STUDENT_PROFILE'), to: "" },
        // { label: t('VISIT_HISTORY'), to: "/visit" }
      ]}
      showHeadingButton={false}
    >
      <VmTable
        loading={studentStore.loading}
        page={page}
        paginationCount={studentStore.studentProfile.totalCount == 0 ? 1 : studentStore.studentProfile.totalCount / PAGE_SIZE}
        onChangePagination={(event: any, pageNumber: number) => {
          setPage(pageNumber);
          studentStore.getStudentProfileListByOrgId(GetOrgId(), PAGE_SIZE, (pageNumber - 1) * PAGE_SIZE)
        }}
        thead={["ID", t('NAME'), t('USERNAME'), t('EMAIL'), t('BOD'), t('ACTION')]}
        tbody={studentStore.studentProfile.data.length > 0 &&
          studentStore.studentProfile.data.map((student: any, index: number) => (
            <>
              <Box marginY={1} />
              <tr key={`student_list_${index}`}>
                <td className={TD_FIRST}>{index + 1}</td>
                <td className={TD_NORMAL}>{student.firstName + " " + student.lastName}</td>
                <td className={TD_NORMAL}>{student.username}</td>
                <td className={TD_NORMAL}>{student.emailAddress}</td>
                <td className={TD_NORMAL}>{student.birthDate.split('T')[0]}</td>
                <td className={TD_LAST}>
                  <Button
                    variant="outlined"
                    sx={{ marginRight: 2 }}
                    onClick={() => {
                      // studentStore.getVisitListByStudentId(student.id, false, PAGE_SIZE, 0);
                      // studentStore.getVisitListByTraineeshipId(student.id);
                      studentStore.getStudentProfileById(student.id, GetOrgId()); // 3 --> get org id may occur error
                      // setVisitCurrentPage(1);
                      // setVisitHistoryPage(1);
                      // setTabValue(1);
                      setFold(0);
                      setCurrentStudent(student);
                      setVisitCurrentPage(0);
                      setCurrentTraineeShip(0);
                      setCheckTimeAvailability({ ...checkTimeAvailability, traineeId: student.id });
                    }}
                  >
                    {t('TRAINEESHIP LIST')}
                  </Button>
                </td>
              </tr>
              <Box marginY={1} />
            </>
          ))}
      />

      {/* Traineeship  */}
      {currentStudent &&
        <>
          <div className="flex justify-between my-4">
            <Typography className="lg:text-lg 2xl:text-2xl" fontWeight={'bold'} marginTop={1}
              sx={{ borderLeftWidth: 8, borderColor: 'black', height: 'fit-content', paddingLeft: 2 }}>
              {t('Traineeship List')}: {currentStudent && currentStudent.firstName + " " + currentStudent.lastName}
            </Typography>

          </div>

          {/* Traineeship list */}
          <TabPanel>
            <VmTable
              loading={studentStore.loadingTrainee}
              page={0}

              thead={["ID", "Enrolled in Course", "Course", "Accessor", "Relevant Organisation", t('ACTION')]}
              tbody={studentStore.oneStudentProfile.traineeships &&
                studentStore.oneStudentProfile.traineeships.length ?
                studentStore.oneStudentProfile.traineeships.map((traineeship: any, index: number) => (
                  <>
                    <Box marginY={1} />
                    {/* <tr key={`visit_${index}`}> */}
                    <tr key={`visit_${index}`} style={{ cursor: "pointer" }}>
                      <td className={TD_FIRST} onClick={() => onToggleTraineeshipFold(traineeship.id)}>{index + 1}</td>
                      <td className={TD_NORMAL} onClick={() => onToggleTraineeshipFold(traineeship.id)}>{traineeship.qualification.name}</td>
                      <td className={TD_NORMAL} onClick={() => onToggleTraineeshipFold(traineeship.id)}>{traineeship.qualification.code}</td>
                      {traineeship.trainer !== null ? <>
                        <td className={TD_NORMAL} onClick={() => onToggleTraineeshipFold(traineeship.id)}>{traineeship.trainer.firstName} {traineeship.trainer.lastName}</td>
                      </> : <td className={TD_NORMAL} >No Trainer</td>}
                      <td className={TD_NORMAL} onClick={() => onToggleTraineeshipFold(traineeship.id)}>{traineeship.organisation.name}</td>
                      <td className={TD_LAST}>
                        <Button
                          variant="outlined"
                          sx={{ marginRight: 2 }}
                          onClick={() => {
                            setFold(0);
                            setShowNeedRequestList(false);
                            studentStore.getVisitListByTraineeshipId(traineeship.id)
                              .then((visitList: any) => {
                                if (visitRef.current) visitRef.current.scrollIntoView(({ behavior: 'smooth' }));
                                if (visitList.length > 0 && visitList.filter((v: any) => !v.isTimeLocationConfirmed).length > 0) {
                                  rootStore.notify(`${visitList.filter((v: any) => !v.isTimeLocationConfirmed).length} visits is requesting time change!`, 'warning');
                                  setShowNeedRequestList(true);
                                  setTabValue(0);
                                }
                              });
                            setVisitCurrentPage(1);
                            setVisitHistoryPage(1);
                            setCurrentTraineeShip(traineeship);
                            setTabValue(1);
                            // @ts-ignore

                            traineeship.trainer && setCheckTimeAvailability({ ...checkTimeAvailability, trainerId: traineeship.trainer.id, traineeshipId: traineeship.id })
                          }}
                        >
                          {t('VISIT_LIST')}
                        </Button>
                      </td>
                    </tr>
                    <TraineeshipCard traineeship={traineeship} fold={traineeFold} colSpan={6} />
                    <Box marginY={1} />
                  </>
                ))
                : <tr><td colSpan={7} className="text-center py-2">{t('NO_HISTORY_FOUND')}</td></tr>
              }
            />
          </TabPanel>
        </>
      }

      {/* Traineeship end */}
      {/* Visit List Tab */}
      {currentTraineeShip &&
        <>
          <div className="flex justify-between my-4">
            <Typography variant="h5" fontWeight={'bold'} marginTop={1}
              sx={{ borderLeftWidth: 8, borderColor: 'black', height: 'fit-content', paddingLeft: 2 }}>
              {t('VISIT_LIST')}: {currentStudent && currentStudent.firstName + " " + currentStudent.lastName}
            </Typography>
            {studentStore.traineeshipVisitList.data.filter((v: any) => !v.isTimeLocationConfirmed).length > 0 &&
              <Button variant="outlined" color={showNeedRequestList ? 'info' : 'error'} onClick={() => {
                if (showNeedRequestList) setTabValue(1);
                else setTabValue(0);
                setShowNeedRequestList(!showNeedRequestList);
              }}>
                {showNeedRequestList ? 'Show All Visits' : 'Show Visits Pending for Time Changes'}
              </Button>}
          </div>
          <Box sx={ITEM_PERFECT_INLINED} ref={visitRef} className="mb-2 justify-between">
            <Box sx={ITEM_PERFECT_INLINED}>
              <span className="bg-sky-400 text-sky-400 rounded-2xl text-white p-2 pt-1 mr-2" />
              <Typography>Trainer & Assesor Not Check In Yet</Typography>
              <span className="bg-orange-400 text-orange-400 rounded-2xl text-white p-2 pt-1 ml-4 mr-2" />
              <Typography> Check In By Trainer & Assesor</Typography>
              <span className="bg-green-400 text-green-400 rounded-2xl text-white p-2 pt-1 ml-4 mr-2" />
              <Typography> Check Out By Trainer & Assesor</Typography>
            </Box>
            <Button variant="outlined" sx={{ ml: 1 }} onClick={() => {
              studentStore.getVisitListByTraineeshipId(currentTraineeShip.id);
              setFold(0)
            }}>
              <RefreshIcon />
            </Button>
          </Box>


          {/* Pending for change list */}
          <TabPanel value={tabValue} index={0}>
            <VmTable
              loading={studentStore.loadingVisit}
              page={0}
              thead={["ID", t('VISIT_NAME'), "Date", t('START_TIME'), t('END_TIME'), "Check In Time", "Check Out Time", "File Upload Status", "Check in " + t('STATUS'), t('ACTION')]}
              // tbody={studentStore.visitCurrentList.data.length > 0 ?
              //   studentStore.visitCurrentList.data.map((visit: any, index: number) => (
              tbody={studentStore.traineeshipVisitList.data.filter((v: any) => !v.isTimeLocationConfirmed).length > 0 ?
                studentStore.traineeshipVisitList.data.filter((v: any) => !v.isTimeLocationConfirmed).map((visit: any, index: number) => (
                  <>
                    <Box marginY={1} />
                    <tr key={`visit_${index}`} style={{ cursor: "pointer" }}>
                      <td className={visit.isTimeLocationConfirmed ? TD_FIRST : TD_FIRST_PENDING_REQUEST} onClick={() => onToggleFold(visit.id)}>{index + 1}</td>
                      <td className={visit.isTimeLocationConfirmed ? TD_NORMAL : TD_NORMAL_PENDING_REQUEST} onClick={() => onToggleFold(visit.id)}>{visit.name}</td>
                      <td className={visit.isTimeLocationConfirmed ? TD_NORMAL : TD_NORMAL_PENDING_REQUEST} onClick={() => onToggleFold(visit.id)}>{visit.finalStartTime.split('T')[0]}</td>
                      <td className={visit.isTimeLocationConfirmed ? TD_NORMAL : TD_NORMAL_PENDING_REQUEST} onClick={() => onToggleFold(visit.id)}>{visit.finalStartTime.split('T')[1]}</td>
                      <td className={visit.isTimeLocationConfirmed ? TD_NORMAL : TD_NORMAL_PENDING_REQUEST} onClick={() => onToggleFold(visit.id)}>{visit.finalEndTime.split('T')[1]}</td>
                      <td className={visit.isTimeLocationConfirmed ? TD_NORMAL : TD_NORMAL_PENDING_REQUEST} onClick={() => onToggleFold(visit.id)}>{visit.checkInTime ? visit.checkInTime.split('T')[1] : "No Check In Time Yet"}</td>
                      <td className={visit.isTimeLocationConfirmed ? TD_NORMAL : TD_NORMAL_PENDING_REQUEST} onClick={() => onToggleFold(visit.id)}>{visit.checkOutTime ? visit.checkOutTime.split('T')[1] : "No Check In Time Yet"}</td>
                      <td className={visit.isTimeLocationConfirmed ? TD_NORMAL : TD_NORMAL_PENDING_REQUEST} title={visit.organisationVisitDocuments.length > 0 ? "Files Uploaded" : "No File Uploaded"} onClick={() => onToggleFold(visit.id)}>
                        {visit.organisationVisitDocuments.length > 0 ?
                          <Box sx={ITEM_PERFECT_INLINED} className="text-green-500 text-3xl pl-8" textAlign={"center"}><FileDownloadDoneIcon fontSize="inherit" /></Box>
                          : <Box sx={ITEM_PERFECT_INLINED} className="text-red-500 text-3xl pl-8" textAlign={"center"}><FileDownloadOffIcon /></Box>}
                      </td>
                      <td className={visit.isTimeLocationConfirmed ? TD_NORMAL : TD_NORMAL_PENDING_REQUEST} onClick={() => onToggleFold(visit.id)}>
                        <span className={`${visit.checkInTime ? "bg-orange-400" : "bg-sky-400"} rounded-2xl text-white p-2 pt-1`}>
                          <Typography variant="caption">{visit.checkInTime ? t('CHECKED_IN_BY_TEACHER') : t('NOT_CHECK_IN_BY_TEACHER')}</Typography>
                        </span>
                      </td>
                      <td className={visit.isTimeLocationConfirmed ? TD_LAST : TD_LAST_PENDING_REQUEST}>
                        {!visit.checkInTime ?
                          <Button
                            variant="outlined"
                            onClick={() => {
                              isBrowser && handleCreateVisitModal(visit.id);
                              setVisitFinalStartTime(new Date(visit.finalStartTime));
                              setCheckTimeAvailability({ ...checkTimeAvailability, visitId: visit.id })

                            }
                            }

                            style={{ backgroundColor: "white" }}
                          >
                            {t('RESCHEDULE')}
                          </Button>
                          :
                          <Button
                            variant="outlined"
                            disabled
                          >
                            {t('RESCHEDULE')}
                          </Button>
                        }
                      </td>
                    </tr>
                    <VmVisitSlideDownCard
                      visit={visit}
                      fold={fold}
                      loadingVisitTable={visitStore.loadingVisitTable}
                      currentTraineeship={currentTraineeShip}
                      visitChangeList={visitStore.visitChangeList}
                      colspan={10}
                    />
                    {/* folder */}
                    {/* <tr className={fold === visit.id ? "" : "hidden"}> */}

                    <Box marginY={1} />
                  </>
                )) : <tr><td colSpan={10} className="text-center py-2">{t('NO_HISTORY_FOUND')}</td></tr>}
            />
          </TabPanel>
          {/* Current List */}
          <TabPanel value={tabValue} index={1}>
            <VmTable
              loading={studentStore.loadingVisit}
              page={0}
              thead={["ID", t('VISIT_NAME'), "Date", t('START_TIME'), t('END_TIME'), "Check In Time", "Check Out Time", "File Upload Status", "Check in " + t('STATUS'), t('ACTION')]}
              // tbody={studentStore.visitCurrentList.data.length > 0 ?
              //   studentStore.visitCurrentList.data.map((visit: any, index: number) => (
              tbody={studentStore.traineeshipVisitList.data.length > 0 ?
                studentStore.traineeshipVisitList.data.map((visit: any, index: number) => (
                  <>
                    <Box marginY={1} />
                    <tr key={`visit_${index}`} style={{ cursor: "pointer" }}>
                      <td className={visit.isTimeLocationConfirmed ? TD_FIRST : TD_FIRST_PENDING_REQUEST} onClick={() => onToggleFold(visit.id)}>{index + 1}</td>
                      <td className={visit.isTimeLocationConfirmed ? TD_NORMAL : TD_NORMAL_PENDING_REQUEST} onClick={() => onToggleFold(visit.id)}>{visit.name}</td>
                      <td className={visit.isTimeLocationConfirmed ? TD_NORMAL : TD_NORMAL_PENDING_REQUEST} onClick={() => onToggleFold(visit.id)}>{visit.finalStartTime.split('T')[0]}</td>
                      <td className={visit.isTimeLocationConfirmed ? TD_NORMAL : TD_NORMAL_PENDING_REQUEST} onClick={() => onToggleFold(visit.id)}>{visit.finalStartTime.split('T')[1]}</td>
                      <td className={visit.isTimeLocationConfirmed ? TD_NORMAL : TD_NORMAL_PENDING_REQUEST} onClick={() => onToggleFold(visit.id)}>{visit.finalEndTime.split('T')[1]}</td>
                      <td className={visit.isTimeLocationConfirmed ? TD_NORMAL : TD_NORMAL_PENDING_REQUEST} onClick={() => onToggleFold(visit.id)}>{visit.checkInTime ? visit.checkInTime.split('T')[1] : "No Check In Time Yet"}</td>
                      <td className={visit.isTimeLocationConfirmed ? TD_NORMAL : TD_NORMAL_PENDING_REQUEST} onClick={() => onToggleFold(visit.id)}>{visit.checkOutTime ? visit.checkOutTime.split('T')[1] : "No Check In Time Yet"}</td>
                      <td className={visit.isTimeLocationConfirmed ? TD_NORMAL : TD_NORMAL_PENDING_REQUEST} title={visit.organisationVisitDocuments.length > 0 ? "Files Uploaded" : "No File Uploaded"} onClick={() => onToggleFold(visit.id)}>
                        {visit.organisationVisitDocuments.length > 0 ?
                          <Box sx={ITEM_PERFECT_INLINED} className="text-green-500 text-3xl pl-8" textAlign={"center"}><FileDownloadDoneIcon fontSize="inherit" /></Box>
                          : <Box sx={ITEM_PERFECT_INLINED} className="text-red-500 text-3xl pl-8" textAlign={"center"}><FileDownloadOffIcon /></Box>}
                      </td>
                      <td className={visit.isTimeLocationConfirmed ? TD_NORMAL : TD_NORMAL_PENDING_REQUEST} onClick={() => onToggleFold(visit.id)}>
                        <span className={`${visit.checkInTime ? "bg-orange-400" : "bg-sky-400"} rounded-2xl text-white p-2 pt-1`}>
                          <Typography variant="caption">{visit.checkInTime ? t('CHECKED_IN_BY_TEACHER') : t('NOT_CHECK_IN_BY_TEACHER')}</Typography>
                        </span>
                      </td>
                      <td className={visit.isTimeLocationConfirmed ? TD_LAST : TD_LAST_PENDING_REQUEST}>
                        {!visit.checkInTime ?
                          <Button
                            variant="outlined"
                            onClick={() => {
                              isBrowser && handleCreateVisitModal(visit.id);
                              setVisitFinalStartTime(new Date(visit.finalStartTime));
                              setCheckTimeAvailability({ ...checkTimeAvailability, visitId: visit.id })

                            }
                            }

                            style={{ backgroundColor: "white" }}
                          >
                            {t('RESCHEDULE')}
                          </Button>
                          :
                          <Button
                            variant="outlined"
                            disabled
                          >
                            {t('RESCHEDULE')}
                          </Button>
                        }
                      </td>
                    </tr>
                    <VmVisitSlideDownCard
                      visit={visit}
                      fold={fold}
                      loadingVisitTable={visitStore.loadingVisitTable}
                      currentTraineeship={currentTraineeShip}
                      visitChangeList={visitStore.visitChangeList}
                      colspan={10}
                    />
                    {/* folder */}
                    {/* <tr className={fold === visit.id ? "" : "hidden"}> */}

                    <Box marginY={1} />
                  </>
                )) : <tr><td colSpan={10} className="text-center py-2">{t('NO_HISTORY_FOUND')}</td></tr>}
            />
          </TabPanel>
        </>}

      {/* Create Visit Modal */}
      <VmModal
        openModal={createVisitModal}
        onClose={handleCloseCreateVisitModal}
        width={600}
        title={t('RESCHEDULE')}
        showButton={false}>
        {currentCreateVisit &&
          <>
            <form onSubmit={createVisitPostHanlder}>
              <FormGroup>
                {/* <FormControl>
                  <InputLabel htmlFor="locationId">Location</InputLabel>
                  <Input style={{ marginBottom: "10px" }} placeholder={currentCreateVisit.locationId} onChange={createVisitPropHandler} value={createVisitProp.locationId} name={"locationId"}></Input>
                  <Select
                    id="locationId"
                    value={createVisitProp.locationId}
                    label="locationId"
                    onChange={createVisitPropHandler}
                    name={"locationId"}
                    style={{ marginBottom: "10px" }}
                  >
                    {visitStore.organisationList.data.map((org: any) => (
                      <MenuItem value={org.id}>{org.name}</MenuItem>
                    ))}
                  </Select>
                </FormControl> */}
                <TextField style={{ marginBottom: "10px" }} id="startTime" label="Start Time" type="datetime-local" defaultValue={currentCreateVisit.finalStartTime} onChange={createVisitPropHandler} value={createVisitProp.startTime} name={"startTime"} />
                <TextField style={{ marginBottom: "10px" }} id="endTime" label="End Time" type="datetime-local" defaultValue={currentCreateVisit.finalEndTime} onChange={createVisitPropHandler} value={createVisitProp.endTime} name={"endTime"} />
                {
                  checkTimeResponse && checkTimeResponse.map((time: any) =>

                    <Box sx={{ margin: "5% 10%", textAlign: "center", backgroundColor: "#ed9d9d", borderRadius: "10px", border: "2px red solid", padding: "1rem" }}>
                      <Typography mb={2} mt={1}>&nbsp;Time Conflict</Typography>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell sx={CHECK_TIME_RESPONSE}>Start Time</TableCell>
                            <TableCell sx={CHECK_TIME_RESPONSE}>End Time</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow>
                            <TableCell sx={CHECK_TIME_RESPONSE}>{time.overlapVisitTime.startTime.split("T")[0]} {time.overlapVisitTime.startTime.split("T")[1]}</TableCell>
                            <TableCell sx={CHECK_TIME_RESPONSE}>{time.overlapVisitTime.endTime.split("T")[0]} {time.overlapVisitTime.endTime.split("T")[1]}</TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </Box>


                  )
                }
                <Box sx={ITEM_PERFECT_INLINED} className="justify-end">
                  {confirmButton ? <>
                    <Button variant="outlined" disabled={createVisitProp.startTime == currentCreateVisit.finalStartTime && createVisitProp.endTime == currentCreateVisit.finalEndTime} onClick={() => createVisitPostHandler()}>Submit Confirm</Button>
                    <Button sx={{ ml: 2 }} variant="outlined" color={"error"} onClick={handleCloseCreateVisitModal}>Cancel</Button>
                  </> : <Button variant="outlined" disabled={createVisitProp.startTime == currentCreateVisit.finalStartTime && createVisitProp.endTime == currentCreateVisit.finalEndTime} onClick={() => setConfirmButton(true)}>Submit</Button>}
                </Box>
              </FormGroup>
            </form>
          </>
        }
      </VmModal>
    </ContentLayout>
  )
});

const ITEM_PERFECT_INLINED = { display: 'flex', alignItems: 'center', flexWrap: 'wrap' };
const CHECK_TIME_RESPONSE = {
  textAlign: "center",
  borderTop: "1px white solid",
  borderBottom: "none"
};

export default StudentOverviewPage;
