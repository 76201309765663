import { Dialog, DialogTitle, DialogContent, DialogActions, DialogContentText, Box, Button, Card, CardHeader, CardContent, Table, TableContainer, TableBody, TableCell, TableHead, TableRow, CircularProgress, Typography } from "@mui/material";
import SlideDown from "react-slidedown"
import React, { useState } from 'react';

import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import HistoryIcon from '@mui/icons-material/History';
import HourglassFullIcon from '@mui/icons-material/HourglassFull';
import TopicIcon from '@mui/icons-material/Topic';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import PendingIcon from '@mui/icons-material/Pending';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import useStores from "../hooks/use-stores";
import { constructImgUrl } from "../constants/settings";
import { FeedbackType, FileCategory, FileType } from "../constants/options";


const VmVisitSlideDownCard = (
    { visit, visitChangeList, loadingVisitTable, fold, currentTraineeship, colspan }
        : { visit: any, visitChangeList: any, loadingVisitTable: boolean, fold: number, currentTraineeship: any, colspan: number }) => {

    // Store
    const { visitStore, studentStore, fileStore, rootStore } = useStores();
    const [confirmOpen, setConfirmOpen] = useState<boolean>(false);
    const [changeRequestAcceptReject, setChangeRequestAcceptReject] = useState<boolean>(false);


    const statusPostHandler = (visitId: number, changeId: number, status: boolean, currentTraineeship: any) => {
        // const res = confirm(`Do you want to ${status === true ? "Accept the request?" : "Reject the request?"}?`)

        visitStore.updateChangeRequestStatus(changeId, status).then(() => {
            //studentStore.getVisitListByTraineeshipId(currentTraineeShip);
            let thisVisit = visitStore.visitChangeList.find((v: any) => v.id === changeId);
            console.log(`visit accept status , status: ${status}, orgAccept: ${thisVisit.orgAccept}, trainerAccept: ${thisVisit.trainerAccept}, traineeAccept: ${thisVisit.traineeAccept}`)
            if (status === true && thisVisit.trainerAccept === true && thisVisit.traineeAccept === true) {
                studentStore.getVisitListByTraineeshipId(currentTraineeship.id);
                visitStore.getChangeRequestListByVisitId(visitId);
            } else {
                visitStore.getChangeRequestListByVisitId(visitId);
            }
        })
        rootStore.notify(`Decision sent successfully`, 'success');

        setConfirmOpen(false);
    }

    //find units which belongs to current visit
    const findTraineeshiptResult = (unitId: number) => {
        let unit = currentTraineeship.traineeshipUnits.find((t: any) => t.unit.id === unitId);
        if (unit.result == FeedbackType.COMPETENT) return 'Competency Achieved';
        else if (unit.result == FeedbackType.CREDIT_TRANSFER) return 'Credit Transfer';
        else if (unit.result == FeedbackType.INCOMPETENT) return 'Continuing';
        else return 'Commenced';
    }



    return (
        <>
            <tr>
                <td colSpan={colspan}>
                    <SlideDown closed={fold !== visit.id}>
                        <div style={{ backgroundColor: "#e4f5f1", borderRadius: "5px", padding: "20px", gap: "1rem", display: "flex", justifyContent: "space-between" }}>
                            <Card style={{ padding: "0 10px", flex: ".3" }}>
                                <CardHeader />
                                <CardContent>
                                    <AccountCircleIcon color="primary" sx={{ fontSize: 100 }} />
                                    <Box mt={2} mb={1}>
                                        <Typography fontWeight="bold" fontSize="1.2rem" mb={1}>Visit Detail</Typography>
                                        <Typography mb={1}><b>Name: </b>{visit.name}</Typography>
                                        <Typography mb={1}><b>Description: </b>{visit.description}</Typography>
                                        <Typography mb={1}><b>Trainer Name: </b>{visit.responsibleTrainerName}</Typography>
                                        <Typography mb={1}><b>Start Time: </b>{visit.finalStartTime.split("T")[0]} {visit.finalStartTime.split("T")[1].split(":")[0]}:{visit.finalStartTime.split("T")[1].split(":")[1]}</Typography>
                                        <Typography mb={1}><b>End Time: </b>{visit.finalEndTime.split("T")[0]} {visit.finalEndTime.split("T")[1].split(":")[0]}:{visit.finalEndTime.split("T")[1].split(":")[1]}</Typography>
                                        <Typography mb={1}><b>Student Name: </b>{visit.studentName}</Typography>
                                        <Typography mb={1}><b>Student Email: </b>{visit.studentEmail}</Typography>
                                        <Typography mb={1}><b>Location: </b>{visit.locationName}</Typography>
                                        <Typography mb={1}><b>Traineeship Organisation Name: </b>{visit.traineeshipOrgName}</Typography>
                                    </Box>
                                </CardContent>
                            </Card>
                            <Card style={{ flex: ".9" }}>

                                {/* Result */}
                                <Box mb={1} style={{ padding: "1rem 1.5rem" }}>
                                    <HourglassFullIcon fontSize="large" sx={{ marginRight: 1 }} color="primary" />
                                    <Typography mt={1} mb={1} sx={{ display: "inline-block", fontSize: 20 }}>Result</Typography>
                                    <TableContainer>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Unit ID</TableCell>
                                                    <TableCell>Unit Name</TableCell>
                                                    <TableCell>Result</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {/* {currentTraineeship.traineeshipUnits.length > 0 ? currentTraineeship.traineeshipUnits.map((unit: any) => {
                                                        visit.unitIdList && visit.unitIdList.includes(unit.id) &&
                                                            <TableRow>
                                                                <TableCell>{unit.unit.code}</TableCell>
                                                                <TableCell>{unit.unit.name}</TableCell>
                                                                <TableCell>{unit.result}</TableCell>
                                                            </TableRow>
                                                        }
                                                    ) : <Typography>No Results Found</Typography>} */}
                                                {visit.visitUnits.length > 0 ?
                                                    visit.visitUnits.map((unit: any) =>
                                                        <TableRow>
                                                            <TableCell>{unit.code}</TableCell>
                                                            <TableCell>{unit.name}</TableCell>
                                                            <TableCell>{findTraineeshiptResult(unit.id)}</TableCell>
                                                        </TableRow>) : <TableCell colSpan={3}><Typography style={{ textAlign: "center", color: "#949492" }}>No Result Found</Typography></TableCell>}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>

                                </Box>
                                {/* File */}

                                <Box mb={1} style={{ padding: "1rem 1.5rem" }}>
                                    <TextSnippetIcon fontSize="large" sx={{ marginRight: 1 }} color="primary" />
                                    <Typography mt={1} mb={1} sx={{ display: 'inline-block', fontSize: 20 }}>File</Typography>
                                    {/* list of files fixed in box */}
                                    {visit.organisationVisitDocuments.length > 0 ?
                                        <Box sx={{ padding: "15px", backgroundColor: "#d4d4d4", display: "flex", flexDirection: "column", alignItems: "center", overflowY: "scroll", height: "130px" }}>
                                            {visit.organisationVisitDocuments.map((doc: any, index: number) => (
                                                // Inside
                                                <Box style={{ display: "flex", justifyContent: "space-between", alignItems: "center", backgroundColor: "#ffffff", width: "100%", padding: 10, borderRadius: "10px", margin: "10px 0" }}>
                                                    <Typography><TopicIcon sx={{ marginRight: 1, fontSize: "30px" }} color="primary" /> <span style={{ color: "#949492", fontSize: "20px" }}>|</span> &nbsp; File {index + 1}</Typography>
                                                    <Button onClick={() => {
                                                        //window.open(constructImgUrl(FileType.VISIT_DOCUMENT, FileCategory.VISIT_DOCUMENT, doc.visitId, doc.physicalName))
                                                        if (doc.physicalName.split('.')[1] !== "pdf") {
                                                            window.open(constructImgUrl(FileType.VISIT_DOCUMENT, FileCategory.VISIT_DOCUMENT, doc.visitId, doc.physicalName));
                                                        } else {
                                                            fileStore.getFile(FileType.VISIT_DOCUMENT, FileCategory.VISIT_DOCUMENT, doc.visitId, doc.physicalName)
                                                                .then((res: any) => {
                                                                    const file = new Blob([res], { type: "application/pdf" });
                                                                    const fileURL = URL.createObjectURL(file);
                                                                    window.open(fileURL);
                                                                })
                                                        }
                                                    }}>
                                                        {doc.documentName}
                                                    </Button>
                                                </Box>
                                            ))}
                                        </Box>
                                        :
                                        <Box sx={{ padding: "15px", backgroundColor: "#d4d4d4", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", height: "100px" }}>
                                            <Typography>No Files Found</Typography>
                                        </Box>
                                    }
                                </Box>

                                {/* Original Time */}
                                <Box style={{ padding: "1rem 1.5rem" }}>
                                    <span>
                                        <AccessTimeIcon sx={{ marginRight: 1 }} color="primary" fontSize="large" />
                                        <Typography style={{ display: 'inline-block', fontSize: 20 }} mt={1} mb={1}>Original Schedule Time</Typography>
                                    </span>

                                    <TableContainer>
                                        <Table style={{ width: "50%" }}>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Date</TableCell>
                                                    <TableCell>StartTime</TableCell>
                                                    <TableCell>End Time</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                <TableCell>{visit.startTime.split("T")[0]}</TableCell>
                                                <TableCell>{visit.startTime.split("T")[1].split(":")[0]}:{visit.startTime.split("T")[1].split(":")[1]} </TableCell>
                                                <TableCell>{visit.endTime.split("T")[1].split(":")[0]}:{visit.endTime.split("T")[1].split(":")[1]}</TableCell>
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Box>

                                {/* Request History */}
                                <Box style={{ padding: "1rem 1.5rem" }}>
                                    <Box mb={1}>
                                        <span>
                                            <HistoryIcon sx={{ marginRight: 1 }} color="primary" fontSize="large" />
                                            <Typography style={{ display: 'inline-block', fontSize: 20 }} mt={1} mb={1}>Request History</Typography>
                                        </span>
                                        {!loadingVisitTable ?
                                            <TableContainer>
                                                <Table>
                                                    <TableHead>
                                                        <TableRow key="">
                                                            <TableCell>Changed By</TableCell>
                                                            <TableCell>Location</TableCell>
                                                            <TableCell>Time</TableCell>
                                                            <TableCell>Status</TableCell>
                                                            <TableCell>Organisation</TableCell>
                                                            <TableCell>Trainer</TableCell>
                                                            <TableCell>Trainee</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {
                                                            // {visitStore.visitChangeList ?
                                                            visitChangeList.length > 0 ? visitChangeList.map((visitChange: any, index: number) => (
                                                                <TableRow key={index}>
                                                                    <TableCell>{visitChange.changedBy.split("-")[0]} - <br />{visitChange.changedBy.split("-")[1]}</TableCell>
                                                                    <TableCell>{visitChange.location.name}</TableCell>
                                                                    <TableCell width={120}>{visitChange.startTime.split("T")[0]} <br /> {visitChange.startTime.split("T")[1].split(":")[0]}:{visitChange.startTime.split("T")[1].split(":")[1]} - {visitChange.endTime.split("T")[1].split(":")[0]}:{visitChange.endTime.split("T")[1].split(":")[1]}</TableCell>
                                                                    <TableCell sx={{ textAlign: "center" }}>{visitChange.orgAccept !== false && visitChange.trainerAccept !== false && visitChange.traineeAccept !== false ? (visitChange.orgAccept === null || visitChange.trainerAccept === null || visitChange.traineeAccept === null ? <span style={{ color: "grey" }}><PendingIcon /></span> : (visitChange.orgAccept && visitChange.trainerAccept && visitChange.traineeAccept ? <span style={{ color: "green" }}><DoneIcon /></span> : <span style={{ color: "red" }}><CloseIcon /></span>)) : <span style={{ color: "red" }}><CloseIcon /></span>}</TableCell>


                                                                    {/* This part maybe more optimised... - June */}
                                                                    <TableCell sx={{ textAlign: "center" }}>
                                                                        {
                                                                            visitChange.orgAccept !== false && visitChange.trainerAccept !== false && visitChange.traineeAccept !== false ?
                                                                                (
                                                                                    visitChange.orgAccept !== null ?
                                                                                        (visitChange.orgAccept ?
                                                                                            <span style={{ color: "green" }}><DoneIcon /></span>
                                                                                            : <span style={{ color: "red" }}><CloseIcon /></span>)
                                                                                        : (<><Button onClick={() => { setConfirmOpen(true); setChangeRequestAcceptReject(true) }}>ACCEPT</Button><br />
                                                                                            <Button color="error" onClick={() => { setConfirmOpen(true); setChangeRequestAcceptReject(false) }}>REJECT</Button></>))

                                                                                : (
                                                                                    visitChange.orgAccept !== null ?
                                                                                        (visitChange.orgAccept ?
                                                                                            <span style={{ color: "green" }}><DoneIcon /></span>
                                                                                            : <span style={{ color: "red" }}><CloseIcon /></span>)
                                                                                        : (<><Button color="primary" disabled>ACCEPT</Button><br />
                                                                                            <Button color="error" disabled>REJECT</Button></>))
                                                                        }
                                                                    </TableCell>
                                                                    <Dialog
                                                                        open={confirmOpen}
                                                                        onClose={() => setConfirmOpen(false)}
                                                                        aria-labelledby="changeRequest-dialog-title"
                                                                        aria-describedby="changeRequest-dialog-description"
                                                                        PaperProps={{
                                                                            style: {
                                                                                borderRadius: "15px",
                                                                                width: "350px"
                                                                            }
                                                                        }}


                                                                    >
                                                                        <DialogTitle id="changeRequest-dialog-title" style={{ backgroundColor: "blue" }}>
                                                                            <Typography fontSize={22} color="#ffffff">Confirm {changeRequestAcceptReject ? "Accept" : "Reject"}?</Typography>
                                                                        </DialogTitle>
                                                                        <DialogContent sx={{ padding: "15px 130px 0 130px" }}>
                                                                            <DialogContentText id="changeRequest-dialog-description">
                                                                                {/* <Typography variant="h6">Confirm {changeRequestAcceptReject ? <Typography variant="h6" component="span" color="#009688">Accept</Typography> : <Typography component="span" variant="h6" color="error">Reject</Typography>}?</Typography> */}
                                                                            </DialogContentText>
                                                                        </DialogContent>
                                                                        <DialogActions >
                                                                            <Button onClick={() => statusPostHandler(visit.id, visitChange.id, changeRequestAcceptReject, currentTraineeship.id)} autoFocus>
                                                                                Confirm
                                                                            </Button>
                                                                            <Button onClick={() => setConfirmOpen(false)} color="error">Cancel</Button>
                                                                        </DialogActions>
                                                                    </Dialog>

                                                                    <TableCell sx={{ textAlign: "center" }}>{visitChange.trainerAccept !== null ? (visitChange.trainerAccept ? <span style={{ color: "green" }}><DoneIcon /></span> : <span style={{ color: "red" }}><CloseIcon /></span>) : <span style={{ color: "grey" }}><PendingIcon /></span>}</TableCell>
                                                                    <TableCell sx={{ textAlign: "center" }}>{visitChange.traineeAccept !== null ? (visitChange.traineeAccept ? <span style={{ color: "green" }}><DoneIcon /></span> : <span style={{ color: "red" }}><CloseIcon /></span>) : <span style={{ color: "grey" }}><PendingIcon /></span>}</TableCell>
                                                                </TableRow>
                                                            )) : <TableRow><TableCell colSpan={10}><Typography style={{ textAlign: "center", color: "#949492" }}>No History Found</Typography></TableCell></TableRow>}

                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                            : <Typography style={{ textAlign: "center" }}><CircularProgress /></Typography>}
                                    </Box>
                                </Box>
                            </Card>
                        </div>
                    </SlideDown>
                </td>
            </tr>
        </>
    )
}

export default VmVisitSlideDownCard;