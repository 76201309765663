import { LinearProgress, Box, Button, Card, CardHeader, CardContent, Table, TableContainer, TableBody, TableCell, TableHead, TableRow, CircularProgress, Typography } from "@mui/material";
import React, { useState, useEffect } from 'react';
import SlideDown from 'react-slidedown';
import { SUCCESS_BUBBLE, ERROR_BUBBLE, NOT_ASSESSED_BUBBLE, TRANSFER_BUBBLE } from "../constants/style";
import BookIcon from '@mui/icons-material/Book';
import { FeedbackType } from "../constants/options";

const TraineeshipCard = ({ traineeship, fold, colSpan }: { traineeship: any, fold: number, colSpan: number }) => {

    const [mouseEnter, setMouseEnter] = useState<string>();

    useEffect(() => {
        setMouseEnter("");
    }, [fold])


    const traineeshipUnitsProgress = (traineeshipUnits: any) => {
        const totalTraineeshipUnits = traineeshipUnits.length;
        let countCompetentUnits: number = 0;
        traineeshipUnits.map((unit: any) => {
            if (unit.result === FeedbackType.COMPETENT || unit.result == FeedbackType.CREDIT_TRANSFER) {
                countCompetentUnits++;
            }
        }
        )

        return countCompetentUnits / totalTraineeshipUnits * 100;
    }

    return (
        <>
            <tr>
                <td colSpan={colSpan}>
                    <SlideDown closed={fold !== traineeship.id}>
                        <div style={{ backgroundColor: "#e4f5f1", borderRadius: "5px", padding: "20px", gap: "1rem" }}>
                            <Box sx={{ backgroundColor: "white", padding: '3%', borderRadius: "10px" }}>
                                <div className='mb-2'>
                                    <Typography variant="h4"><BookIcon fontSize="inherit" color="primary" />Units</Typography>
                                </div>
                                <Box sx={ITEM_PERFECT_INLINED} className="mb-2">
                                    <span className="bg-green-400 text-green-400 rounded-2xl text-white p-2 pt-1 mr-2" />
                                    <Typography>Competency Achieved</Typography>
                                    <span className="bg-yellow-400 text-green-400 rounded-2xl text-white p-2 pt-1 ml-4 mr-2" />
                                    <Typography>Credit Transfer</Typography>
                                    <span className="bg-gray-400 text-gray-400 rounded-2xl text-white p-2 pt-1 ml-4 mr-2" />
                                    <Typography>Commenced</Typography>
                                    <span className="bg-orange-500 text-orange-500 rounded-2xl text-white p-2 pt-1 ml-4 mr-2" />
                                    <Typography>Continuing</Typography>
                                </Box>

                                <Box sx={{ width: "100%" }}>
                                    <Box sx={{ display: 'flex', alignItems: "center" }}>
                                        <Box sx={{ width: '100%', mr: 1 }}>
                                            <LinearProgress variant="determinate" value={traineeshipUnitsProgress(traineeship.traineeshipUnits)} />
                                        </Box>
                                        <Box sx={{ minWidth: 35 }}>
                                            <Typography variant="body2" color="text.secondary">{Math.round(traineeshipUnitsProgress(traineeship.traineeshipUnits))}%</Typography>
                                        </Box>
                                    </Box>
                                </Box>
                                <Box mb={2} />

                                {traineeship.traineeshipUnits.length > 0 ? traineeship.traineeshipUnits.map((unit: any) =>
                                    <div style={{ display: "inline-block", transition: "all 0.5s ease", cursor: "pointer" }} onClick={(e) => { mouseEnter !== unit.unit.code ? setMouseEnter(unit.unit.code) : setMouseEnter("") }}>
                                        {unit.result == FeedbackType.COMPETENT ? (mouseEnter === unit.unit.code ? <Typography className={SUCCESS_BUBBLE} style={{ marginRight: "10px", marginBottom: "10px" }} >{unit.unit.code} {unit.unit.name} </Typography> : <Typography className={SUCCESS_BUBBLE} style={{ marginRight: "10px", marginBottom: "10px" }}>{unit.unit.code} </Typography>)
                                            : unit.result == FeedbackType.CREDIT_TRANSFER ? (mouseEnter === unit.unit.code ? <Typography className={TRANSFER_BUBBLE} style={{ marginRight: "10px", marginBottom: "10px" }} >{unit.unit.code} {unit.unit.name} </Typography> : <Typography className={TRANSFER_BUBBLE} style={{ marginRight: "10px", marginBottom: "10px" }}>{unit.unit.code} </Typography>)
                                                : unit.result === FeedbackType.INCOMPETENT ? (mouseEnter === unit.unit.code ? <Typography className={ERROR_BUBBLE} style={{ marginRight: "10px", marginBottom: "10px" }}>{unit.unit.code} {unit.unit.name}</Typography> : <Typography className={ERROR_BUBBLE} style={{ marginRight: "10px", marginBottom: "10px" }}>{unit.unit.code} </Typography>)
                                                    : (mouseEnter === unit.unit.code ? <Typography className={NOT_ASSESSED_BUBBLE} style={{ marginRight: "10px", marginBottom: "10px" }}>{unit.unit.code} {unit.unit.name}</Typography> : <Typography className={NOT_ASSESSED_BUBBLE} style={{ marginRight: "10px", marginBottom: "10px" }}>{unit.unit.code} </Typography>)}
                                    </div>
                                ) : <Typography style={{ textAlign: "center", color: "#949492" }}>No Units Found</Typography>}
                            </Box>
                        </div>
                    </SlideDown>
                </td>
            </tr>
        </>
    )
}

const ITEM_PERFECT_INLINED = { display: 'flex', alignItems: 'center', flexWrap: 'wrap' };

export default TraineeshipCard;